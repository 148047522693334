var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "lang-icon" },
    [
      _c(
        "Dropdown",
        { attrs: { transfer: "" }, on: { "on-click": _vm.langChange } },
        [
          _c("Icon", { attrs: { type: "md-globe", size: "26" } }),
          _c(
            "DropdownMenu",
            { attrs: { slot: "list" }, slot: "list" },
            [
              _c(
                "DropdownItem",
                { attrs: { name: "zh-CN", selected: _vm.currLang == "zh-CN" } },
                [
                  _c("div", { staticClass: "lan-logo-content" }, [
                    _c("img", {
                      staticClass: "country-logo",
                      attrs: { src: require("@/assets/icon/chinese.png") },
                    }),
                    _vm._v("简体中文 "),
                  ]),
                ]
              ),
              _c(
                "DropdownItem",
                { attrs: { name: "en-US", selected: _vm.currLang == "en-US" } },
                [
                  _c("div", { staticClass: "lan-logo-content" }, [
                    _c("img", {
                      staticClass: "country-logo",
                      attrs: { src: require("@/assets/icon/english.png") },
                    }),
                    _vm._v("English "),
                  ]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }